:root {
  --brand-color: #2563eb;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.overlay {
  position: absolute;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(40, 44, 52, 0.5);
  z-index: 1;
}

.login_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border-radius: 10px;
  min-width: 235px;
  min-height: 235px;
  color: white;
}

.glassmorphed {
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

.input_field_wrapper {
  margin: 16px;
}

.input_field_wrapper .input {
  position: relative;
}

.input_field_wrapper .input_field {
  box-sizing: border-box;
  display: block;
  border: 0px solid;
  outline: none;
  border-bottom: 1px solid white;
  color: white;
  padding: 12px 8px;
  font-size: 1.1rem;
  background: transparent;
}

.input_field:not(:-moz-placeholder-shown) + .input_label {
  transform: translate(0.25rem, -65%) scale(0.8);
}

.input_field:not(:-ms-input-placeholder) + .input_label {
  transform: translate(0.25rem, -65%) scale(0.8);
}

.input_field:focus + .input_label,
.input_field:not(:placeholder-shown) + .input_label {
  transform: translate(0.25rem, -65%) scale(0.8);
}

.input_field_wrapper .input_label {
  position: absolute;
  left: 0;
  top: 0;
  padding: 6px 0px;
  margin: 9px 0px;
  white-space: nowrap;
  transform: translate(0, 0);
  transform-origin: 0 0;
  transition: transform 120ms ease-in;
  font-weight: bold;
  line-height: 1.2;
  color: #e5e3e3;
}

.login-button {
  width: 72px;
  margin: 16px;
  background-color: var(--brand-color);
  color: white;
  border: 0;
  font-size: 1.1rem;
  font-weight: 700;
  border-radius: 6px;
  height: 40px;
  align-self: flex-end;
}

.login-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.button-ripple {
  position: relative;
  overflow: hidden;
  outline: none;
  cursor: pointer;
  background-position: center;
  transition: background 0.8s;
}

.button-ripple:hover {
  background: #2563ebad radial-gradient(circle, transparent 1%, #2563ebad 1%)
    center/15000%;
}

.button-ripple:active {
  background-color: #7497b4;
  background-size: 100%;
  transition: filter 0s;
}

.waiting-display {
  padding-bottom: 20px;
}

.game {
  position: absolute;
  height: 100vh;
  width: 100vw;
  max-width: 100%;
  max-height: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: black;
}

.color-block {
  transition-property: height;
  transition-duration: 200ms;
  transition-timing-function: ease-out;
  width: 100%;
}

.result-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.result-text {
  color: white;
  font-size: 1.5rem;
  margin: 10px;
}

.play-again {
  font-size: 1rem;
  padding: 10px;
  color: white;
  border-width: 0;
  font-weight: 700;
  border-radius: 6px;
  height: 40px;
  cursor: pointer;
}

.name-tag {
  position: absolute;
  right: 10px;
  background: rgba(0, 0, 0, 0.6);
  color: white;
  padding: 8px;
  border-radius: 6px;
  pointer-events: none;
}

.header {
  position: absolute;
  top: 16px;
  right: 16px;
  color: white;
  display: flex;
  flex-direction: row;
  justify-self: flex-end;
  align-self: flex-end;
}
.header > a {
  color: white;
  padding-left: 12px;
}

.footer {
  position: absolute;
  bottom: 16px;
  color: white;
}
.footer > a {
  color: white;
}

.tooltip {
  position: relative;
  display: inline-block;
  padding-left: 12px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 200px;
  text-align: center;
  border-radius: 6px;
  padding: 12px;
  position: absolute;
  z-index: 1;
  top: 150%;
  left: 50%;
  margin-left: -100px;
}

.tooltip .tooltiptext::after {
  content: '';
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent rgba(255, 255, 255, 0.25) transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
